








































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { SousTypeCreneau, UpdateSousTypeCreneauModel } from "@/api/models/typesCreneau/sousTypeCreneau";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "update-sous-type-creneau",
})
export default class UpdateSousTypeCreneau extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateSousTypeCreneauSidebarActive!: boolean;
  @Prop({ required: true }) sousTypeCreneauId!: string;

  loading = false;
  required = required;
  loadingSousTypeCreneau = false;

  sousTypeCreneau: UpdateSousTypeCreneauModel = {};

  async created() {
    await this.$http.myressif.typesCreneau
      .getSousTypeCreneauById(this.$route.params.id, this.sousTypeCreneauId)
      .then((res: SousTypeCreneau) => {
        this.sousTypeCreneau = res;
        this.loadingSousTypeCreneau = true;
      });
  }

  resetForm() {
    this.sousTypeCreneau = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.typesCreneau
      .updateSousTypeCreneauTypeCreneau(
        this.$route.params.id,
        this.sousTypeCreneauId,
        this.sousTypeCreneau
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification d'un sous-type de créneau",
            text: "Sous-type de créneau modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-sous-type-creneau");
        this.$emit("update:is-update-sous-type-creneau-sidebar-active", false);
        this.loading = false;
      });
  }
}
