

































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { TypeCreneau } from "@/api/models/typesCreneau/typeCreneau";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { SousTypeCreneau } from "@/api/models/typesCreneau/sousTypeCreneau";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import AjoutSousTypeCreneau from "./AjoutSousTypeCreneau.vue"
import UpdateSousTypeCreneau from "./UpdateSousTypeCreneau.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BSpinner,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    AjoutSousTypeCreneau,
    UpdateSousTypeCreneau
  },
})
export default class ListTypesCreneau extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  typeCreneauSelected: TypeCreneau | null = null;
  mainLoading = true;

  isAddNewSousTypeCreneauSidebarActive = false;
  isUpdateSousTypeCreneauSidebarActive = false;
  selectedSousTypeCreneauId = "";


  tableColumns = [
    { key: "libelle", sortable: true, label: "libellé" },
    { key: "actions", sortable: false }
  ];
  paginatedListSousTypesCreneau = new PaginatedList<SousTypeCreneau>();

  async created() {
    this.mainLoading = true;
    await this.$http.myressif.typesCreneau
      .getById(this.$route.params.id)
      .then((response: TypeCreneau) => {
        this.typeCreneauSelected = response;
      });

    await this.loadListSousTypesCreneau({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
    this.mainLoading = false;
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListSousTypesCreneau.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListSousTypesCreneau.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListSousTypesCreneau({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  goToList() {
    this.$router.push({ name: "types-creneau" });
  }

  async loadListSousTypesCreneau(params: any) {
    if (!params || params?.reset) {
      this.paginatedListSousTypesCreneau = new PaginatedList<SousTypeCreneau>();
    }
    await this.$http.myressif.typesCreneau
      .getListSousTypesCreneauByTypeCreneauId(this.$route.params.id)
      .then((response: PaginatedList<SousTypeCreneau>) => {
        this.paginatedListSousTypesCreneau = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce sous-type de créneau ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.typesCreneau
      .deleteSousTypeCreneauTypeCreneau(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un sous-type de créneau",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        await this.loadListSousTypesCreneau({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }

  editSousTypeCreneau(sousTypeCreneauId: string) {
    this.selectedSousTypeCreneauId = sousTypeCreneauId;
    this.isUpdateSousTypeCreneauSidebarActive = true;
  }
}
